// main.ts
import ChatWidget from "./ChatWidget.svelte";
class ConciergeChatWidget extends HTMLElement {
    static get observedAttributes() {
        return ["apiToken"];
    }
    constructor() {
        super();
        this.widgetInstance = null;
        const shadowRoot = this.attachShadow({ mode: "open" });
        // Create and append global styles for the shadow DOM
        this.containerStyle = document.createElement("style");
        this.containerStyle.textContent = `
      :host {
        display: block;
        position: relative;
        height: 100%;
        overflow: hidden;
      }
      
      .widget-container {
        height: 100%;
        overflow: hidden;
      }
    `;
        shadowRoot.appendChild(this.containerStyle);
        // Create a container for the Svelte component
        const container = document.createElement("div");
        container.className = "widget-container";
        shadowRoot.appendChild(container);
        // Initialize the Svelte component instance
        this.widgetInstance = new ChatWidget({
            target: container,
            props: {
                apiToken: this.getAttribute("apiToken") || "",
            },
        });
        // Add global event listeners to prevent parent scroll
        this.preventParentScroll();
    }
    preventParentScroll() {
        const handleWheel = (e) => {
            const target = e.target;
            const scrollableElement = target.closest(".message-container");
            if (scrollableElement instanceof HTMLElement) {
                const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
                const isScrollable = scrollHeight > clientHeight;
                const isAtTop = scrollTop <= 0;
                const isAtBottom = scrollTop + clientHeight >= scrollHeight;
                // Allow scrolling only when the container can scroll in that direction
                if (isScrollable && ((e.deltaY > 0 && !isAtBottom) || (e.deltaY < 0 && !isAtTop))) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        };
        // Touch event handling
        let touchStartY = 0;
        let currentScrollableElement = null;
        const handleTouchStart = (e) => {
            touchStartY = e.touches[0].clientY;
            const target = e.target;
            currentScrollableElement = target.closest(".message-container");
        };
        const handleTouchMove = (e) => {
            if (!currentScrollableElement)
                return;
            const touchY = e.touches[0].clientY;
            const touchDelta = touchStartY - touchY;
            const { scrollTop, scrollHeight, clientHeight } = currentScrollableElement;
            // Check if scroll is possible in the direction of touch
            const canScrollUp = scrollTop > 0 && touchDelta < 0;
            const canScrollDown = scrollTop < scrollHeight - clientHeight && touchDelta > 0;
            if (canScrollUp || canScrollDown) {
                e.stopPropagation();
                e.preventDefault();
            }
        };
        if (this.shadowRoot) {
            // Add event listeners with proper types
            this.shadowRoot.addEventListener("wheel", handleWheel, {
                passive: false,
            });
            this.shadowRoot.addEventListener("touchstart", handleTouchStart, {
                passive: true,
            });
            this.shadowRoot.addEventListener("touchmove", handleTouchMove, {
                passive: false,
            });
        }
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "apiToken" && this.widgetInstance) {
            this.widgetInstance.$set({ apiToken: newValue || "" });
        }
    }
    disconnectedCallback() {
        if (this.widgetInstance) {
            this.widgetInstance.$destroy();
        }
    }
}
// Register the custom element globally
customElements.define("concierge-chat-widget", ConciergeChatWidget);
