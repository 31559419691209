<script>
  import SkeletonLine from "./SkeletonLine.svelte";
</script>

<div class="concierge-chat-skeleton-container">
  <SkeletonLine width="30%" />
  <SkeletonLine width="90%" />
  <SkeletonLine width="90%" />
  <SkeletonLine width="90%" />
  <SkeletonLine width="50%" />
</div>

<style>
  .concierge-chat-skeleton-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin: 10px;
    width: 100%;
  }
</style>
