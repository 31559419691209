<script lang="ts">
  export let width = "70px";
  export let borderRadius = "4px";
</script>

<div
  class="concierge-chat-skeleton-line"
  style="--element-width: {width}; --element-border-radius: {borderRadius};"
></div>

<style>
  .concierge-chat-skeleton-line {
    float: left;
    width: var(--element-width);
    height: 15px;
    border-radius: var(--element-border-radius);
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 200px;
    animation: shine-lines 1.6s infinite linear;
  }

  @keyframes shine-lines {
    0% {
      background-position: -200px 0;
    }

    100% {
      background-position: 200px 0;
    }
  }
</style>
