<script lang="ts">
  import closeIcon from "./assets/images/close.icon.svg";
  import { botInfo, showExternalInfoModal, apiTokenValue } from "./store";
  import LogoLoader from "./SharedComponent/LogoLoader.svelte";
  import { sendContactInfo } from "./service/service";

  let invalidEmail: boolean = false;
  let isCompleted: boolean = false;
  let isLoading: boolean = false;
  let formData: { [key: string]: string } = {};

  function handleSubmit(event: any) {
    event.preventDefault();
    if (!validateEmail(formData.email)) {
      invalidEmail = !validateEmail(formData.email);

      return;
    }
    submitContactInfo();
  }

  async function submitContactInfo() {
    try {
      isLoading = true;
      const items: { externalInfoId: string; value: string }[] = Object.entries(formData)
        .map(([key, value]) => {
          const matchingInfo = $botInfo.conversationExtraInfo?.find((i) => i.key === key);
          return matchingInfo ? { externalInfoId: matchingInfo.id, value } : null;
        })
        .filter((item) => item !== null) as { externalInfoId: string; value: string }[];
      await sendContactInfo(items, $apiTokenValue);
      localStorage.setItem("ConciergeChatShowExternalUserInfoBar", "false");

      isLoading = false;
      isCompleted = true;
    } catch (error) {
      isLoading = false;
    }
  }

  function validateEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailPattern.test(email);
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  }
  function closeModal() {
    showExternalInfoModal.set(false);
  }
</script>

<div
  class="concierge-chat-external-user-info-modal-container"
  class:concierge-chat-external-user-info-modal-container-visible={$showExternalInfoModal}
>
  <div
    class="concierge-chat-external-user-info-modal-body"
    class:concierge-chat-external-user-info-modal-body-visible={$showExternalInfoModal}
    style={`height: ${($botInfo?.conversationExtraInfo?.length || 1) * 50 + 200}px`}
  >
    {#if isLoading}
      <div class="concierge-chat-external-user-info-modal-completed">
        <LogoLoader
          color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetPrimaryColor || "#0e3c26"}
        />
      </div>
    {/if}
    {#if !isCompleted && !isLoading}
      <span class="concierge-chat-external-user-info-modal-header">
        <p>Please input contact info</p>
        <button on:click={closeModal}>
          <img src={closeIcon} alt="close-modal" />
        </button>
      </span>
      <form on:submit={handleSubmit}>
        {#if $botInfo?.conversationExtraInfo && $botInfo?.conversationExtraInfo.length > 0}
          {#each $botInfo?.conversationExtraInfo as extraInfo}
            <div>
              <label for={extraInfo.key}>{extraInfo.key} :</label>
              <input
                type="text"
                id={extraInfo.key}
                bind:value={formData[extraInfo.key]}
                placeholder={`Enter your ${extraInfo.key}`}
                style="border-color:{invalidEmail && extraInfo.key === 'email'
                  ? 'red'
                  : '#d2d2d2'};"
              />
            </div>
          {/each}
        {/if}

        <button
          on:keydown={handleKeyDown}
          style="--element-background-color: {$botInfo?.settings?.widgetAppearanceBehavior
            ?.widgetPrimaryColor || '#0e3c26'};"
          type="submit"
        >
          Submit
        </button>
      </form>
    {/if}
    {#if isCompleted && !isLoading}
      <div class="concierge-chat-external-user-info-modal-completed">
        <p>Thank you for your submission!</p>
        <button
          style="--element-background-color: {$botInfo?.settings?.widgetAppearanceBehavior
            ?.widgetPrimaryColor || '#0e3c26'};"
          on:click={closeModal}
        >
          Close</button
        >
      </div>
    {/if}
  </div>
</div>

<style>
  .concierge-chat-external-user-info-modal-container {
    z-index: 9999;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    background-color: #00000030;
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .concierge-chat-external-user-info-modal-container-visible {
    height: calc(100% - 68px);
    opacity: 1;
  }

  .concierge-chat-external-user-info-modal-body {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #ffffff;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    opacity: 0;
    transform: translateY(300px);
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }

  .concierge-chat-external-user-info-modal-body-visible {
    opacity: 1;
    transform: translateY(0px);
  }

  .concierge-chat-external-user-info-modal-header {
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
    padding: 0px 25px;
  }

  .concierge-chat-external-user-info-modal-header > p {
    font-size: 14px;
    margin: 0px;
    font-weight: bold;
  }

  .concierge-chat-external-user-info-modal-header > button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    line-height: normal;
  }
  .concierge-chat-external-user-info-modal-header > button > img {
    width: 18px;
    max-width: 18px;
  }
  .concierge-chat-external-user-info-modal-body > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 15px;
    padding: 10px 20px;
  }

  .concierge-chat-external-user-info-modal-body > form > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 250px;

    gap: 3px;
  }

  .concierge-chat-external-user-info-modal-body > form > div > label {
    font-size: 12px;
  }
  .concierge-chat-external-user-info-modal-body > form > div > input {
    border-radius: 10px;
    border: 1px solid #d2d2d2;
    outline: none;
    margin: 0px;
    max-height: 30px;
    height: 30px;
    padding: 0px 10px;
    width: 100%;
  }

  .concierge-chat-external-user-info-modal-body > form > div > input::placeholder {
    color: #d2d2d2;
  }
  .concierge-chat-external-user-info-modal-body > form > button {
    margin-top: 20px;

    border-radius: 10px;
    background-color: var(--element-background-color);
    outline: none;
    width: 250px;
    max-height: 30px;
    height: 30px;
    padding: 0px 10px;
    align-self: center;
  }

  .concierge-chat-external-user-info-modal-completed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 20px;
    flex: 1;
  }

  .concierge-chat-external-user-info-modal-completed > p {
    font-size: 14px;
    margin: 0px;
    font-weight: bold;
  }
  .concierge-chat-external-user-info-modal-completed > button {
    margin-top: 20px;
    border-radius: 10px;
    background-color: var(--element-background-color);
    outline: none;
    width: 80px;
    max-height: 30px;
    height: 30px;
    padding: 0px 10px;
    align-self: center;
  }
</style>
