<script>
  export let strokeColor = "none";
  export let width = "250";
  export let height = "250";
</script>

<svg {width} {height} id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <g fill={strokeColor} id="Layer_1-2">
    <circle class="cls-1" cx="73.82" cy="55.03" r="21.41" />
    <ellipse
      class="cls-1"
      cx="34.33"
      cy="62.95"
      rx="13.34"
      ry="14.12"
      transform="translate(-7.77 4.91) rotate(-7.35)"
    />
    <ellipse
      class="cls-1"
      cx="7.99"
      cy="69.21"
      rx="7.97"
      ry="8.76"
      transform="translate(-11.11 2.21) rotate(-9.33)"
    />
  </g></svg
>
