<script lang="ts">
  import {
    fetchMessageLoading,
    isWidgetOpen,
    sendMessageLoading,
    messageList,
    apiTokenValue,
    botInfo,
  } from "./store";
  import closeIcon from "./assets/images/close.icon.svg";
  import minimizeIcon from "./assets/images/minimize.icon.svg";
  import maximizeIcon from "./assets/images/maximize.icon.svg";
  import logoIcon from "./assets/images/logo.icon.svg";
  import { afterUpdate, onMount } from "svelte";
  import MessageRowComponent from "./MessageRowComponent.svelte";
  import ChatForm from "./ChatForm.svelte";
  import { initializeWidget, type Message, type QuickReply } from "./service/service";
  import SkeletonLine from "./SharedComponent/SkeletonLine.svelte";
  import SkeletonAvatar from "./SharedComponent/SkeletonAvatar.svelte";
  import SkeletonView from "./SharedComponent/SkeletonView.svelte";
  import QuickReplyContainer from "./QuickReplyContainer.svelte";
  import LogoLoader from "./SharedComponent/LogoLoader.svelte";
  import ExternalUserInfoBar from "./ExternalUserInfoBar.svelte";
  import ExternalUserInfoModal from "./ExternalUserInfoModal.svelte";
  const maxWithSupportExpanded = 800;
  let messageListData: Message[] = [];
  let messageContainer: HTMLDivElement | null = null;
  let expanded =
    window.innerWidth < maxWithSupportExpanded
      ? false
      : $botInfo?.settings?.widgetViewMode === "expand"
        ? true
        : false;
  let isExpandable = false;
  let windowWidth: number;

  export let aiMessageConverter: any;
  export let userMessageConverter: any;

  messageList.subscribe((list) => {
    messageListData = list;
  });
  let formQuickReplyHandler: any;

  function closeChat() {
    isWidgetOpen.set(false);
    if (window !== undefined) {
      window.document.body.style.overflow = "auto";
    }
  }

  const disableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "hidden";
    }
  };

  const enableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "auto";
    }
  };

  const updateWindowWidth = () => {
    windowWidth = window.innerWidth;

    if (windowWidth < maxWithSupportExpanded) {
      expanded = false;
      isExpandable = false;
      if (window !== undefined) {
        window.document.body.style.overflow = "auto";
      }
    }
    if (windowWidth >= maxWithSupportExpanded) {
      isExpandable = true;
    }
  };

  onMount(() => {
    // getAllMessages();
    updateWindowWidth();

    if (window !== undefined && expanded) {
      window.document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", updateWindowWidth);

    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  });

  afterUpdate(() => {
    scrollToBottom();
  });

  function changeExpandMode() {
    expanded = !expanded;

    if (expanded && window !== undefined) {
      window.document.body.style.overflow = "hidden";
    }

    if (!expanded && window !== undefined) {
      window.document.body.style.overflow = "auto";
    }
  }
  // async function getAllMessages() {
  //   fetchMessageLoading.set(true);
  //   const { messages, project } = await initializeWidget($apiTokenValue);
  //   botInfo.set(project);
  //   messageList.set(messages);
  //   fetchMessageLoading.set(false);
  // }

  function scrollToBottom() {
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  function sendQr(event: any) {
    if (formQuickReplyHandler && formQuickReplyHandler.handleQuickReply) {
      formQuickReplyHandler.handleQuickReply(event.detail.data as QuickReply);
    }
  }
</script>

<div
  on:mouseenter={disableBodyScroll}
  on:mouseleave={enableBodyScroll}
  role="region"
  aria-label="Chat Container"
  class:concierge-chat-box-backdrop={expanded}
>
  <div
    class="concierge-chat-box"
    class:concierge-chat-box-minimized={!expanded}
    class:concierge-chat-box-maximized={expanded}
  >
    <div class="concierge-chat-box-header">
      <div class="concierge-chat-box-header-title">
        <span>
          {#if $fetchMessageLoading}
            <SkeletonAvatar />
          {:else if $botInfo?.settings?.botImageUrl}
            <img src={$botInfo?.settings?.botImageUrl} alt="logo" />
          {:else}
            <span class="concierge-chat-box-header-brand-container"
              ><img src={logoIcon} alt="brand" />
            </span>
          {/if}
        </span>

        <div>
          {#if $fetchMessageLoading}
            <span><SkeletonLine /></span>
          {:else if $botInfo?.settings?.botName}
            <span>{$botInfo?.settings?.botName}</span>
          {:else}
            <span>Concierge</span>
          {/if}

          <span>Online</span>
        </div>
      </div>

      <div class="concierge-chat-box-header-buttons">
        {#if isExpandable}
          <button class="concierge-chat-box-close-button" on:click={changeExpandMode}>
            <img src={expanded ? minimizeIcon : maximizeIcon} alt="expand" />
          </button>
        {/if}

        <button class="concierge-chat-box-close-button" on:click={closeChat}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
    </div>

    <ExternalUserInfoModal />

    <div class="concierge-chat-box-main-container">
      {#if !$fetchMessageLoading && messageContainer}
        <ExternalUserInfoBar {messageContainer} />
      {/if}
      <div class="concierge-chat-box-body" bind:this={messageContainer}>
        {#if $fetchMessageLoading}
          <SkeletonView />
        {/if}

        {#if !$fetchMessageLoading && $botInfo?.settings?.welcomeMessage}
          <MessageRowComponent
            {expanded}
            content={$botInfo?.settings?.welcomeMessage}
            role={"assistant"}
            {aiMessageConverter}
            {userMessageConverter}
          />
        {/if}
        {#if !$fetchMessageLoading && $botInfo?.quickReplies?.length > 0}
          <QuickReplyContainer quickReplies={$botInfo?.quickReplies} on:sendQr={sendQr} />
        {/if}
        {#each messageListData as message, i}
          <MessageRowComponent
            {expanded}
            content={message.content}
            role={message.role}
            {aiMessageConverter}
            {userMessageConverter}
            assistantMessageId={message?.id || message?.metadata?.assistantMessageId}
            assistantFeedback={message?.assistantFeedback}
          />
        {/each}
        {#if $sendMessageLoading}
          <span class="concierge-ai-loader-container">
            <LogoLoader
              color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetPrimaryColor || "#0e3c26"}
            />
          </span>
        {/if}
      </div>
    </div>
    <ChatForm on:messageReceived={scrollToBottom} bind:this={formQuickReplyHandler} {expanded} />
    <div class="concierge-chat-box-footer">
      <a href="https://webconcierge.ai" target="_blank">Powered by Concierge</a>
    </div>
  </div>
</div>

<style>
  .concierge-chat-box-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000a6;
    backdrop-filter: blur(5px);
  }
  .concierge-chat-box {
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-shadow: 0px 2px 12px 0px #00000033;
    transition:
      width 0.5s ease,
      height 0.5s ease,
      right 0.5s ease,
      bottom 0.5s ease,
      left 0.5s ease,
      top 0.5s ease;
    position: fixed;
    max-width: 90%;
    overflow: hidden;
  }

  .concierge-chat-box-minimized {
    width: 350px;
    height: 600px;
    right: 20px;
    bottom: 20px;
    top: auto;
    left: auto;
  }

  .concierge-chat-box-maximized {
    width: 800px;
    height: 600px;
    right: calc(50% - 400px);
    bottom: calc(50% - 300px);
    top: auto;
    left: auto;
  }
  /*---------------------------*/
  /*------- chat header -------*/
  /*---------------------------*/

  .concierge-chat-box-header {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
    padding: 0px 25px;
    height: 70px;
  }
  .concierge-chat-box-header-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    color: white;
  }
  .concierge-chat-box-header-title > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1px;
  }

  .concierge-chat-box-header-title > div > span:first-child {
    max-height: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    line-height: unset;
    font-size: 16px;
    font-weight: normal;
  }
  .concierge-chat-box-header-title > div > span:last-child {
    font-size: 10px;
    color: #000;
    line-height: unset;
    font-weight: normal;
    /* color: #d2d2d2; */
  }

  .concierge-chat-box-header-title > span {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .concierge-chat-box-header-title > span > img {
    width: 30px;
    height: 30px;
  }

  .concierge-chat-box-header-brand-container {
    background-color: #0e3c26;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .concierge-chat-box-header-brand-container > img {
    width: 20px;
    height: 20px;
  }

  .concierge-chat-box-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  .concierge-chat-box-close-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    line-height: normal;
  }
  .concierge-chat-box-close-button > img {
    width: 18px;
    max-width: 18px;
  }

  /*-------------------------*/
  /*------- chat body -------*/
  /*-------------------------*/

  .concierge-chat-box-main-container {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }
  .concierge-chat-box-body {
    background-color: #ffffff;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    border-bottom: 1px solid #eeeeee;
  }

  .concierge-chat-box-footer {
    position: absolute;
    bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
  }

  .concierge-chat-box-footer > a {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: #0e3c26;
    text-decoration: unset;
    font-weight: bold;
  }
  .concierge-ai-loader-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 5px 0px 5px 10px;
  }
</style>
